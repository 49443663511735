import drdoor from "../../assets/img/portfolio/drdoor.webp"
import vkholidays from "../../assets/img/portfolio/vkholidays.webp"
import vodafoneUK from "../../assets/img/portfolio/vodafoneuk.webp"
import fluentCommerceOMS from "../../assets/img/portfolio/fluentcommerceoms.webp"
import irshadck from "../../assets/img/portfolio/irshadck.webp"

const PortfolioData = [
  {
    id: 1,
    type: "Vodafone UK Official Site - Mobile Phones & Home Broadband",
    image: vodafoneUK,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Web Development",
        client: "Vodafone UK through DXC.technology",
        language:
          "React.js, Mobx, JavaScript, TypeScript, jQuery, Oracle UCMS, Oracle SiteStudio, Oracle WCP, Node.js, PHP Laravel, MySQL, HTML, SASS, CSS, AWS S3, AWS, Agile, Azure",
        preview: "www.vodafone.co.uk",
        link: "https://www.vodafone.co.uk/",
        description:
          "I'm engaged to develop, improve, modernise and support their customer's account self-service portal used by over 4 million customers per month for service journeys on desktop and mobile channels.",
      },
    ],
  },
  {
    id: 2,
    type: "Dr.Door - FRP Door manufacturer",
    image: drdoor,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Web Development",
        client: "Dr.Door",
        language:
          "React.js, Redux, JavaScript, TypeScript, Node.js HTML, SASS, CSS, AWS S3, AWS DynamoDB, AWS Route53, AWS Lambda, AWS EventBridge, Serverless Framework, Git",
        preview: "www.drdoor.in",
        link: "https://www.drdoor.in/",
        description:
          "Dr.Door is a FRP Doors with Solid Surfaces manufacturer. This project is to build a dynamic responsive website for their brand to reach their brand to people. We mainly focused on SEO, Accessibility, Performance while building the website",
      },
    ],
  },
  {
    id: 3,
    type: "VK Holidays - Adventure Holidays, Travel & Tours",
    image: vkholidays,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Web Development",
        client: "VK Holidays",
        language:
          "React.js, Redux, JavaScript, TypeScript, Node.js, HTML, SASS, CSS, AWS S3, AWS DynamoDB, AWS Route53, AWS Lambda, AWS EventBridge, Serverless Framework, Git",
        preview: "www.vkholidays.com",
        link: "https://stellular-cajeta-c1c5c3.netlify.app/",
        description:
          "This dynamic website is developed for VK Holidays to showcase their Holiday packages online",
      },
    ],
  },
  {
    id: 4,
    type: "Fluent Commerce OMS Customization",
    image: fluentCommerceOMS,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Web Development",
        client:
          "Lindt, Wiggle, Primark, Emma-sleep and GameWorkshop through Ayata Commerce",
        language:
          "React.js, JavaScript, TypeScript, Node.js, HTML, SASS, CSS, Git",
        preview: "No preview available as OMS are deployed in private networks",
        link: "#",
        description:
          "Fluent Commerce’s cloud-native distributed order management system is unique. Fluent Order Management (Fluent OMS) is the first cloud platform that can be customized to fit your omnichannel fulfillment strategy. I was involved in customizing the OMS based on client's requirements",
      },
    ],
  },
  {
    id: 5,
    type: "Personal Website - irshadck.com",
    image: irshadck,
    tag: ["Web"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Web Development",
        client: "Irshad CK",
        language:
          "React.js, JavaScript, TypeScript, Node.js, HTML, SASS, CSS, Bootstrap, Git",
        preview: "www.irshadck.com",
        link: "#",
        description:
          "The website which you are currently looking on is my personal website to showcase my skills and experience",
      },
    ],
  },
]

export default PortfolioData
