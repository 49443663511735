import React from "react";

const experienceContent = [
  {
    year: "   2022 - Present",
    position: "Senior Software engineer",
    compnayName: "Ayata Commerce",
    details: ["• Involved in building Reusable React components for Fluent Commerce Order Management System.",
    "• Configured and customized Order Management System and Store for Lindt, Emma, Wiggle and Game Workshop in Fluent Commerce.",
    "• Trained Interns in Fluent Commerce Order Management System"],
  },
  {
    year: "2015 - 2022",
    position: "Software Engineer",
    compnayName: "DXC Technology",
    details: ["• Built responsive with high‑performance front‑end for many service journeys, supported to integrate with backend and test the end‑to‑end system for Vodafone UK.",
    "• Built front‑end, backend and database for online forms.",
    "• Launched lookup tools to help customers check the cost and information of services, reducing the traffic in our IVR.",
    "• Improved customer experience and accessibility by re‑building the service journeys, which had a demonstrable impact across several journeys.",
    "• Increased system efficiency, consistency, field‑proven code and ease of testing by building reusable components.",
    "• Built local development frameworks to improve the development, testing and delivery process.",
    "• Resolved codebase maintainability issues by improving codebase branching strategies and organized the codebase.",
    "• Increased application performance by Modernizing, optimizing the existing code and removing unused codes."]
  }
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{
          val.details.map(item => {
            return <li style={{marginBottom:"16px", paddingLeft:"16px"}}>{item}</li>
          })}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
